define("discourse/plugins/discourse-calendar/discourse/components/event-date", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "discourse/plugins/discourse-calendar/discourse/lib/guess-best-date-format", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _discourseI18n, _guessBestDateFormat, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let EventDate = _exports.default = (_class = (_class2 = class EventDate extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get shouldRender() {
      return this.siteSettings.discourse_post_event_enabled && this.args.topic.event_starts_at && this.args.topic.event_ends_at;
    }
    get eventStartedAt() {
      return this._parsedDate(this.args.topic.event_starts_at);
    }
    get eventEndedAt() {
      return this._parsedDate(this.args.topic.event_ends_at);
    }
    get dateRange() {
      return this.eventEndedAt ? `${this._formattedDate(this.eventStartedAt)} → ${this._formattedDate(this.eventEndedAt)}` : this._formattedDate(this.eventStartedAt);
    }
    get localDateContent() {
      return this._formattedDate(this.eventStartedAt);
    }
    get relativeDateType() {
      if (this.isWithinDateRange) {
        return "current";
      }
      if (this.eventStartedAt.isAfter(moment())) {
        return "future";
      }
      return "past";
    }
    get isWithinDateRange() {
      return this.eventStartedAt.isBefore(moment()) && this.eventEndedAt.isAfter(moment());
    }
    get relativeDateContent() {
      // dateType "current" uses a different implementation
      const relativeDates1 = {
        future: this.eventStartedAt.from(moment()),
        past: this.eventEndedAt.from(moment())
      };
      return relativeDates1[this.relativeDateType];
    }
    get timeRemainingContent() {
      return _discourseI18n.default.t("discourse_calendar.discourse_post_event.topic_title.ends_in_duration", {
        duration: this.eventEndedAt.from(moment())
      });
    }
    _parsedDate(date1) {
      return moment.utc(date1).tz(moment.tz.guess());
    }
    _guessedDateFormat() {
      return (0, _guessBestDateFormat.default)(this.eventStartedAt);
    }
    _formattedDate(date1) {
      return date1.format(this._guessedDateFormat());
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.shouldRender}}
        <div class="header-topic-title-suffix-outlet event-date-container">
          {{#if this.siteSettings.use_local_event_date}}
            <span
              class="event-date event-local-date past"
              title={{this.dateRange}}
              data-starts-at={{this.eventStartedAt}}
              data-ends-at={{this.eventEndedAt}}
            >
              {{this.localDateContent}}
            </span>
          {{else}}
            <span
              class="event-date event-relative-date {{this.relativeDateType}}"
              title={{this.dateRange}}
              data-starts-at={{this.eventStartedAt}}
              data-ends-at={{this.eventEndedAt}}
            >
              {{#if this.isWithinDateRange}}
                <div class="indicator"></div>
                <span class="text">{{this.timeRemainingContent}}</span>
              {{else}}
                {{this.relativeDateContent}}
              {{/if}}
            </span>
          {{/if}}
        </div>
      {{/if}}
    
  */
  {
    "id": "tT13nKxr",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"header-topic-title-suffix-outlet event-date-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"use_local_event_date\"]],[[[1,\"          \"],[10,1],[14,0,\"event-date event-local-date past\"],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[1,\"\\n            \"],[1,[30,0,[\"localDateContent\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[15,0,[29,[\"event-date event-relative-date \",[30,0,[\"relativeDateType\"]]]]],[15,\"title\",[30,0,[\"dateRange\"]]],[15,\"data-starts-at\",[30,0,[\"eventStartedAt\"]]],[15,\"data-ends-at\",[30,0,[\"eventEndedAt\"]]],[12],[1,\"\\n\"],[41,[30,0,[\"isWithinDateRange\"]],[[[1,\"              \"],[10,0],[14,0,\"indicator\"],[12],[13],[1,\"\\n              \"],[10,1],[14,0,\"text\"],[12],[1,[30,0,[\"timeRemainingContent\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"              \"],[1,[30,0,[\"relativeDateContent\"]]],[1,\"\\n\"]],[]]],[1,\"          \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
    "moduleName": "/app/code/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/event-date.js",
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});